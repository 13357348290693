.print-invoice-page {
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 50px;
    padding-bottom: 50px;
}

.print-invoice-page-logo {
    width: 100px;
    height: 100px;
}