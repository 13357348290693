/*:root {*/
/*    --main-bg-color: #083A2D;*/
/*    --main-text-color: #083A2D;*/
/*    --second-text-color: #F5E8C5;*/
/*    --second-bg-color: #F5E8C5;*/
/*}*/

/*a:hover {*/
/*    text-decoration: none;*/
/*    color: var(--main-text-color);*/
/*    opacity: 0.8;*/
/*}*/

.primary-text {
    color: var(--main-text-color);
}

.second-text {
    color: var(--second-text-color);
}

.primary-bg {
    background-color: var(--main-bg-color);
}

.secondary-bg {
    background-color: var(--second-bg-color);
}

.rounded-full {
    border-radius: 100%;
}

#wrapper {
    height: 100vh;
    min-height: 100vh;
    overflow-x: hidden;
    /*background-image: linear-gradient(*/
    /*        to right,*/
    /*        #F5E8C5,*/
    /*        #DAC8AB,*/
    /*        #BFA892,*/
    /*        #A58878,*/
    /*        #8A695E,*/
    /*);*/
}

#sidebar-wrapper {
    display: flex;
    flex-direction: column;
    background-color: var(--main-bg-color);
    color: var(--second-text-color);
    height: 100vh;
    min-height: 100vh;
    margin-left: -15rem;
    -webkit-transition: margin 0.25s ease-out;
    -moz-transition: margin 0.25s ease-out;
    -o-transition: margin 0.25s ease-out;
    transition: margin 0.25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
    padding: 0.875rem 1.25rem;
    font-size: 1.2rem;
}

#sidebar-wrapper .list-group {
    width: 15rem;
}

#page-content-wrapper {
    min-width: 100vw;
    min-height: 100vh;
    overflow-y: auto;
    background-image: linear-gradient(180deg, #F5E8C5  0%, #FFFFFF 90%);
}

#wrapper.toggled #sidebar-wrapper {
    margin-left: 0;
}

#menu-toggle {
    cursor: pointer;
}

.list-group-item {
    border: none;
    padding: 20px 30px;
}

.list-group-item.active {
    background-color: transparent;
    color: var(--main-text-color);
    font-weight: bold;
    border: none;
}

@media (min-width: 768px) {
    #sidebar-wrapper {
        margin-left: 0;
    }

    #page-content-wrapper {
        min-width: 0;
        width: 100%;
    }

    #wrapper.toggled #sidebar-wrapper {
        margin-left: -15rem;
    }
}

@media (min-width: 768px) {
    .mobile-only {
        display: none;
    }
}

/*.page {*/
/*    position: relative;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    min-height: 100vh;*/
/*    height: 100vh;*/
/*    background-color: red;*/
/*}*/

/*!*main {*!*/
/*!*    flex: 1;*!*/
/*!*}*!*/

/*.sidebar {*/
/*    background-image: linear-gradient(180deg, rgb(0, 0, 0) 0%, #808080 70%);*/
/*}*/

/*.top-row {*/
/*    background-color: #f7f7f7;*/
/*    border-bottom: 1px solid #d6d5d5;*/
/*    justify-content: flex-end;*/
/*    height: 3.5rem;*/
/*    display: flex;*/
/*    align-items: center;*/
/*}*/

/*.top-row a, .top-row .btn-link {*/
/*    white-space: nowrap;*/
/*    margin-left: 1.5rem;*/
/*    text-decoration: none;*/
/*}*/

/*.top-row a:hover, .top-row .btn-link:hover {*/
/*    text-decoration: underline;*/
/*}*/

/*.top-row a:first-child {*/
/*    overflow: hidden;*/
/*    text-overflow: ellipsis;*/
/*}*/

/*@media (max-width: 640.98px) {*/
/*    !*.top-row:not(.auth) {*!*/
/*    !*    display: none;*!*/
/*    !*}*!*/
/*    */
/*    !*.top-row.auth {*!*/
/*    !*    justify-content: space-between;*!*/
/*    !*}*!*/

/*    .top-row a, .top-row .btn-link {*/
/*        margin-left: 0;*/
/*    }*/
/*}*/

/*@media (min-width: 641px) {*/
/*    .page {*/
/*        flex-direction: row;*/
/*    }*/

/*    .sidebar {*/
/*        width: 250px;*/
/*        height: 100vh;*/
/*        position: sticky;*/
/*        top: 0;*/
/*    }*/

/*    .top-row {*/
/*        position: sticky;*/
/*        top: 0;*/
/*        z-index: 1;*/
/*    }*/

/*    .top-row.auth a:first-child {*/
/*        flex: 1;*/
/*        text-align: right;*/
/*        width: 0;*/
/*    }*/

/*    .top-row, article {*/
/*        padding-left: 2rem !important;*/
/*        padding-right: 1.5rem !important;*/
/*    }*/
/*}*/
