.day-number {

}

.day-events {

}

.calendar-day {
    font-size: 0.70em;
    color: gray;
}

.event-accommodation {
    color: green;
    font-size: 0.70em;
}

.event-reservation {
    color: blue;
    font-size: 0.70em;
}

.event-house-use {
    color: #AA02BD;
    font-size: 0.70em;
}

.dx-scheduler-date-table-current-date {
    background-color: lightgray;
}

.dx-scheduler-date-table-current-date .dx-scheduler-date-table-cell-text{
    color: black;
}  

.days-list-popup {
}