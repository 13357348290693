:root {
    --main-bg-color: #083A2D;
    --main-text-color: #083A2D;
    --second-text-color: #F5E8C5;
    --second-bg-color: #F5E8C5;
}

a {
    text-decoration: none;
    color: var(--main-text-color);
}

a:hover {
    color: var(--main-text-color);
    opacity: 0.8;
}

.btn-primary, .btn-primary:visited, .btn-primary:focus, .btn-primary:hover, .btn-primary:active {
    color: var(--second-text-color) !important;
    background-color: var(--main-bg-color) !important;
    border-color: var(--main-bg-color) !important;
}

.btn-primary:hover {
    opacity: 0.8;
}

.form-control:focus {
    border-color: var(--main-bg-color);
    box-shadow: none;
    -webkit-box-shadow: none;
}

.has-error .form-control:focus {
    box-shadow: none;
    -webkit-box-shadow: none;
}


/*.btn:focus, .btn:active:focus, .btn-link.nav-link:focus, .form-control:focus, .form-check-input:focus {*/
/*    box-shadow: 0 0 0 0.1rem white, 0 0 0 0.25rem #258cfb;*/
/*}*/
/*html, body {*/
/*    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;*/
/*}*/

/*h1:focus {*/
/*    outline: none;*/
/*}*/

/*a, .btn-link {*/
/*    color: #0071c1;*/
/*}*/


/*.btn:focus, .btn:active:focus, .btn-link.nav-link:focus, .form-control:focus, .form-check-input:focus {*/
/*    box-shadow: 0 0 0 0.1rem white, 0 0 0 0.25rem #258cfb;*/
/*}*/

/*.content {*/
/*    padding-top: 1.1rem;*/
/*}*/

/*.valid.modified:not([type=checkbox]) {*/
/*    outline: 1px solid #26b050;*/
/*}*/

/*.invalid {*/
/*    outline: 1px solid red;*/
/*}*/

/*.validation-message {*/
/*    color: red;*/
/*}*/


